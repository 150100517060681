import React, { useState } from "react";
import "./qualification.css";
import {
  HiOutlineAcademicCap,
  HiOutlineArchive,
  HiOutlineBriefcase,
  HiOutlineCalendar,
} from "react-icons/hi";
import TipTip from "../../assets/tiptip.png";
import SehatQ from "../../assets/sehatq.jpeg";
import LoGan from "../../assets/logan.jpeg";
import Pawoon from "../../assets/pawoon.png";
import KlikQuick from "../../assets/klikquick.jpeg";
import Exera from "../../assets/exera.jpeg";
import NeoFusion from "../../assets/neofusion.png";
import Eragano from "../../assets/eragano.jpeg";
import Sekret from "../../assets/sekret.png";
import { HiOutlineArchiveBox } from "react-icons/hi2";

const Qualification = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <section className="qualification section" id="qualifications">
      <h2 className="section__title">Qualification</h2>
      <span className="section__subtitle">My Journey</span>

      <div className="qualification__container container">
        <div className="qualification__tabs">
          <div
            className={
              toggleState === 1
                ? "qualification__button button--flex qualification__active"
                : "qualification__button button--flex"
            }
            onClick={() => toggleTab(1)}
          >
            <HiOutlineAcademicCap className="qualification__icon" />
            Working Experience
          </div>
          <div
            className={
              toggleState === 2
                ? "qualification__button button--flex qualification__active"
                : "qualification__button button--flex"
            }
            onClick={() => toggleTab(2)}
          >
            <HiOutlineBriefcase className="qualification__icon" />
            Education
          </div>
          <div
            className={
              toggleState === 3
                ? "qualification__button button--flex qualification__active"
                : "qualification__button button--flex"
            }
            onClick={() => toggleTab(3)}
          >
            <HiOutlineArchiveBox className="qualification__icon" />
            Bootstrap
          </div>
        </div>

        <div className="qualification__sections">
          <div
            className={
              toggleState === 1
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >
            <div className="qualification__data">
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={TipTip}
                    alt="about"
                    className="about__img"
                    style={{ width: 40, marginTop: 6 }}
                  />
                  <h3 className="qualification__title">Mobile Engineer</h3>

                  <span className="qualification__subtitle">TipTip</span>
                </div>
                <div className="qualification__calendar">
                  <HiOutlineCalendar
                    className="qualification__calendar-icon"
                    style={{ marginRight: 6 }}
                  />
                  Jul 2023 - Present
                </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>
            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={SehatQ}
                    alt="about"
                    className="about__img"
                    style={{ width: 40, marginTop: 6 }}
                  />
                  <h3 className="qualification__title">
                    Engineering Squad Lead
                  </h3>

                  <span className="qualification__subtitle">SehatQ</span>
                </div>
                <div className="qualification__calendar">
                  <HiOutlineCalendar
                    className="qualification__calendar-icon"
                    style={{ marginRight: 6 }}
                  />
                  Nov 2022 - Oct 2023
                </div>
              </div>
            </div>
            <div className="qualification__data">
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={SehatQ}
                    alt="about"
                    className="about__img"
                    style={{ width: 40, marginTop: 6 }}
                  />
                  <h3 className="qualification__title">
                    Senior Android Engineer
                  </h3>

                  <span className="qualification__subtitle">SehatQ</span>
                </div>
                <div className="qualification__calendar">
                  <HiOutlineCalendar
                    className="qualification__calendar-icon"
                    style={{ marginRight: 6 }}
                  />
                  Sep 2020 - Oct 2023
                </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>
            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={LoGan}
                    alt="about"
                    className="about__img"
                    style={{ width: 40, marginTop: 6 }}
                  />
                  <h3 className="qualification__title">
                    Senior Android Engineer
                  </h3>

                  <span className="qualification__subtitle">
                    LoGan Ahwaya Nusantara
                  </span>
                </div>
                <div className="qualification__calendar">
                  <HiOutlineCalendar
                    className="qualification__calendar-icon"
                    style={{ marginRight: 6 }}
                  />
                  Nov 2019 - Sep 2020
                </div>
              </div>
            </div>

            <div className="qualification__data">
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={Pawoon}
                    alt="about"
                    className="about__img"
                    style={{ width: 40, marginTop: 6 }}
                  />
                  <h3 className="qualification__title">Android Engineer</h3>

                  <span className="qualification__subtitle">Pawoon</span>
                </div>
                <div className="qualification__calendar">
                  <HiOutlineCalendar
                    className="qualification__calendar-icon"
                    style={{ marginRight: 6 }}
                  />
                  May 2018 - Sep 2020
                </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={KlikQuick}
                    alt="about"
                    className="about__img"
                    style={{ width: 40, marginTop: 6 }}
                  />
                  <h3 className="qualification__title">
                    Freelance Android Engineer
                  </h3>

                  <span className="qualification__subtitle">klikQuick</span>
                </div>
                <div className="qualification__calendar">
                  <HiOutlineCalendar
                    className="qualification__calendar-icon"
                    style={{ marginRight: 6 }}
                  />
                  Apr 2017 - Nov 2018
                </div>
              </div>
            </div>

            <div className="qualification__data">
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={Exera}
                    alt="about"
                    className="about__img"
                    style={{ width: 40, marginTop: 6 }}
                  />
                  <h3 className="qualification__title">Android Engineer</h3>

                  <span className="qualification__subtitle">
                    Laxus Exera Technology
                  </span>
                </div>
                <div className="qualification__calendar">
                  <HiOutlineCalendar
                    className="qualification__calendar-icon"
                    style={{ marginRight: 6 }}
                  />
                  Jul 2017 - May 2018
                </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={NeoFusion}
                    alt="about"
                    className="about__img"
                    style={{ width: 40, marginTop: 6 }}
                  />
                  <h3 className="qualification__title">Android Engineer</h3>

                  <span className="qualification__subtitle">
                    Neo Fusion Indonesia
                  </span>
                </div>
                <div className="qualification__calendar">
                  <HiOutlineCalendar
                    className="qualification__calendar-icon"
                    style={{ marginRight: 6 }}
                  />
                  Jul 2017 - Oct 2017
                </div>
              </div>
            </div>

            <div className="qualification__data">
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={Eragano}
                    alt="about"
                    className="about__img"
                    style={{ width: 40, marginTop: 6 }}
                  />
                  <h3 className="qualification__title">Android Engineer</h3>

                  <span className="qualification__subtitle">Eragano</span>
                </div>
                <div className="qualification__calendar">
                  <HiOutlineCalendar
                    className="qualification__calendar-icon"
                    style={{ marginRight: 6 }}
                  />
                  Apr 2016 - Jul 2017
                </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>

            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={Sekret}
                    alt="about"
                    className="about__img"
                    style={{ width: 40, marginTop: 6 }}
                  />
                  <h3 className="qualification__title">Intern Web Engineer</h3>

                  <span className="qualification__subtitle">
                    Kementrian Sekretariat Negara RI
                  </span>
                </div>
                <div className="qualification__calendar">
                  <HiOutlineCalendar
                    className="qualification__calendar-icon"
                    style={{ marginRight: 6 }}
                  />
                  Aug 2015 - Sep 2015
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              toggleState === 2
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">IPB University</h3>
                <span className="qualification__subtitle">
                  Diploma, Management Informatics
                </span>
                <div className="qualification__calendar">
                  <HiOutlineCalendar
                    className="qualification__calendar-icon"
                    style={{ marginRight: 6 }}
                  />
                  2014-2017
                </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>
            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
              <div>
                <h3 className="qualification__title">SMAN 3 Negeri Depok</h3>
                <span className="qualification__subtitle">SMA</span>
                <div className="qualification__calendar">
                  <HiOutlineCalendar
                    className="qualification__calendar-icon"
                    style={{ marginRight: 6 }}
                  />
                  2011-2014
                </div>
              </div>
            </div>
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">SMP 2 Negeri Depok</h3>
                <span className="qualification__subtitle">SMP</span>
                <div className="qualification__calendar">
                  <HiOutlineCalendar
                    className="qualification__calendar-icon"
                    style={{ marginRight: 6 }}
                  />
                  2018-2011
                </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>
            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
              <div>
                <h3 className="qualification__title">
                  SDN Negeri Depok Jaya 1
                </h3>
                <span className="qualification__subtitle">SD</span>
                <div className="qualification__calendar">
                  <HiOutlineCalendar
                    className="qualification__calendar-icon"
                    style={{ marginRight: 6 }}
                  />
                  2002-2008
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              toggleState === 3
                ? "qualification__content qualification__content-active"
                : "qualification__content"
            }
          >
            <div className="qualification__data">
              <div>
                <h3 className="qualification__title">
                  Co Founder & Android Engineer
                </h3>
                <span className="qualification__subtitle">
                  Dadar Development
                </span>
                <div className="qualification__calendar">
                  <HiOutlineCalendar
                    className="qualification__calendar-icon"
                    style={{ marginRight: 6 }}
                  />
                  Dec 2015 - Present (Vacumm)
                </div>
              </div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
            </div>
            <div className="qualification__data">
              <div></div>
              <div>
                <span className="qualification__rounder"></span>
                <span className="qualification__line"></span>
              </div>
              <div>
                <h3 className="qualification__title">
                  Chief Technology Officer
                </h3>
                <span className="qualification__subtitle">
                  Nocturnal Studio
                </span>
                <div className="qualification__calendar">
                  <HiOutlineCalendar
                    className="qualification__calendar-icon"
                    style={{ marginRight: 6 }}
                  />
                  Apr 2017 - Dec 2017
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Qualification;

import Image1 from "../../assets/woman1.png";
import Image2 from "../../assets/man.png";
import Image3 from "../../assets/woman2.png";

import Porto2 from "../../assets/porto/2.png";
import Porto3 from "../../assets/porto/3.png";
import Porto4 from "../../assets/porto/4.png";
import Porto5 from "../../assets/porto/5.png";
import Porto6 from "../../assets/porto/6.png";
import Porto7 from "../../assets/porto/7.png";
import Porto8 from "../../assets/porto/8.png";
import Porto9 from "../../assets/porto/9.png";
import Porto10 from "../../assets/porto/10.png";
import Porto11 from "../../assets/porto/11.png";
import Porto12 from "../../assets/porto/12.png";
import Porto13 from "../../assets/porto/13.png";

export const Data = [
  {
    image: Porto2,
  },
  {
    image: Porto3,
  },
  {
    image: Porto4,
  },
    {
        image: Porto5,
    },
    {
        image: Porto6,
    },
    {
        image: Porto7,
    },
    {
        image: Porto8,
    },
    {
        image: Porto9,
    },
    {
        image: Porto10,
    },
    {
        image: Porto11,
    },
    {
        image: Porto12,
    },
    {
        image: Porto13,
    }
];

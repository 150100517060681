import React from "react";
import "./home.css";
import Social from "./Social";
import Data from "./Data";
import Scroll from "./Scroll";

import { TypeAnimation } from "react-type-animation";

const Home = () => {
  return (
    <section className="home section" id="home">
      <div className="home__container container grid">
        <div className="home__content grid">
          <Social />
          <div className="home__img">
          <TypeAnimation
            sequence={[
              // Same substring at the start will only be typed out once, initially
              "I built Android Apps",
              1000, // wait 1s before replacing "Mice" with "Hamsters"
              "I built iOS Apps",
              1000,
              "I built Flutter Apps",
              1000,
              "I built React Native Apps",
              1000,
            ]}
            wrapper="span"
            speed={40}
            style={{ fontSize: "2.5em", display: "inline-block" }}
            repeat={Infinity}
          />
          </div>
          <Data />
        </div>
        <Scroll />
      </div>
    </section>
  );
};

export default Home;

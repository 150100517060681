import { DiAndroid, DiAtom } from "react-icons/di";
import { HiArrowCircleRight } from "react-icons/hi";

const ItemApp = ({ appName, appDescription, isHybrid, link }) => {
  return (
    <div
      style={{
        alignItems: "center",
        marginBottom: 14,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {isHybrid ? <DiAtom /> : <DiAndroid />}
        <p style={{ marginLeft: 10, fontSize: 13, color: "#000" }}>{appName}</p>
      </div>
      <div style={{ marginLeft: 14 }}>
        <p style={{ marginLeft: 10, fontSize: 11 }}>{appDescription}</p>
        {link ? (
          <a href={link}  target="_blank" rel="noopener noreferrer">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft:10,
                marginTop: 4,
              }}
            >
              <p style={{ fontSize: 10, marginRight: 2 }}>view detail</p>
              <HiArrowCircleRight />
            </div>
          </a>
        ) : null}
      </div>
    </div>
  );
};

export default ItemApp;

import React from "react";
import "./testimonials.css";
import { Data } from "./Data";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/zoom";
// import required modules
import { Zoom, Navigation, Pagination, Autoplay } from "swiper";
import ItemApp from "./ItemApp";

const Testimonials = () => {
  return (
    <section className="testimonial container section" id="testimonial">
      <h2 className="section__title">Project Showcase</h2>
      <span className="section__subtitle">Some Project i have done</span>
      <Swiper
        className="testimonial__container"
        style={{width: '100%'}}
        loop={true}
        // grabCursor={true}
        spaceBetween={24}
        effect={"fade"}
        fadeEffect={{
          crossFade: true,
        }}
        zoom={true}
        autoplay={{
          delay: 4000,
        }}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          576: {
            slidesPerView: 1,
            spaceBetween: 48,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 48,
          },
        }}
        modules={[Zoom, Navigation, Pagination, Autoplay]}
      >
        {Data.map(({ id, image }) => {
          return (
            <SwiperSlide className="testimonial__card" key={id}>
              <div className="swiper-zoom-container" >
                <img src={image} alt="client" />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>

      <h2 className="section__title" style={{ marginTop: 20 }}>
        Another Project
      </h2>
      <span className="section__subtitle" style={{ marginBottom: 0 }}>
        Other project i've been working on
      </span>
      <span className="section__subtitle" style={{ marginBottom: 20 }}>
        (some apps already not published or internal apps)
      </span>

      <div
        className="testimonial__container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className="testimonial__card">
          <span
            className="section__subtitle"
            style={{ marginBottom: 20, color: "#000" }}
          >
            Android Native Apps
          </span>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 1.5 }}>
              <ItemApp
                appName={"INFrame"}
                appDescription={
                  "Apps for exchange task in class college & community upload file"
                }
              />
              <ItemApp
                appName={"Eragano"}
                appDescription={"Apps for farmer to sell their product"}
              />
              <ItemApp
                appName={"Eragano Assessment"}
                appDescription={"Apps for internal team for assessment farmer"}
              />
              <ItemApp
                appName={"Hangout Bogor"}
                appDescription={
                  "Apps catalog for finding restaurant, cafe or entertainment place in Bogor"
                }
              />
              <ItemApp
                appName={"Bogor Queritur"}
                appDescription={
                  "Apps catalog for finding restaurant, cafe or entertainment place in Bogor"
                }
              />
              <ItemApp
                appName={"Toyota Head Unit App"}
                appDescription={
                  "Apps for Toyota Head unit including contact, all service place, explaining product & guidance"
                }
              />
              <ItemApp
                appName={"KlikQuick Customer & Driver App"}
                appDescription={
                  "Apps for customer to order food & driver to deliver food"
                }
                link={
                  "https://play.google.com/store/apps/details?id=klikquickmapp.klikquick.com.klikquickapp&hl=in"
                }
              />
              <ItemApp
                appName={"#DriveOn"}
                appDescription={
                  "Shell member Apps for gaining point & reward from Oil change"
                }
              />
              <ItemApp
                appName={"Autoglaze"}
                appDescription={"Apps for Car Wash & Car Detailing"}
                link={
                  "https://play.google.com/store/apps/details?id=com.autoglaze.customer"
                }
              />
            </div>
            <div style={{ flex: 1 }}>
              <ItemApp
                appName={"Sedekahnesia"}
                appDescription={"Apps for donation & social activity"}
              />
              <ItemApp
                appName={"Cinta Bali"}
                appDescription={
                  "Apps for exploring Bali including Flight & Hotel Booking"
                }
              />
              <ItemApp
                appName={"Pro BPRS Mobile Bank"}
                appDescription={
                  "App Banking for BPRS to Assess & Signing up customer"
                }
              />
              <ItemApp
                appName={"App Gate FIF"}
                appDescription={"Apps for FIF to unify all apps in one apps"}
              />
              <ItemApp
                appName={"Pawoon Cashier App"}
                appDescription={"Cashier App"}
                link={
                  "https://play.google.com/store/apps/details?id=com.pawoon.pos"
                }
              />
              <ItemApp
                appName={"Logan App"}
                appDescription={"Apps for Driver & Transporter"}
                link={
                  "https://play.google.com/store/apps/details?id=com.logan.main"
                }
              />
              <ItemApp
                appName={"SehatQ Customer & Doctor"}
                appDescription={"Complete Medical Health Apps"}
                link={
                  "https://play.google.com/store/apps/details?id=com.she.sehatq"
                }
              />
            </div>
          </div>
          <span
            className="section__subtitle"
            style={{ marginBottom: 2, color: "#000", marginTop: 20 }}
          >
            Hybrid Apps
          </span>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 1 }}>
              <ItemApp
                appName={"Dins Burger Indonesia"}
                appDescription={
                  "Apps for selling raw material to all franchise Dins Burger"
                }
                isHybrid={true}
              />
              <ItemApp
                appName={"Cosmetic Commerce"}
                isHybrid={true}
                appDescription={"Apps for selling cosmetic product"}
              />
              <ItemApp
                appName={"Rich Railing"}
                isHybrid={true}
                appDescription={"Apps for selling building materials "}
              />
              <ItemApp
                appName={"SuperNinja"}
                isHybrid={true}
                appDescription={
                  "Superindo Official Market Apps for buying groceries"
                }
                link={
                  "https://play.google.com/store/apps/details?id=id.co.superindo.superninja"
                }
              />
              <ItemApp
                appName={"Sipgar"}
                isHybrid={true}
                appDescription={
                  "Apps for government to measure physical & health of civil servants"
                }
                link={
                  "https://play.google.com/store/apps/details?id=com.sipgar.id"
                }
              />
              <ItemApp
                appName={"Sipgar Game"}
                isHybrid={true}
                appDescription={
                  "Game Apps for gaining point with sport with Tracking Movement user"
                }
                link={
                  "https://play.google.com/store/apps/details?id=com.sipgar.fitasik.id"
                }
              />
              <ItemApp
                appName={"Java Jazz Festival"}
                isHybrid={true}
                appDescription={
                  "Apps for ticketing and event information of Java Jazz Festival"
                }
                link={
                  "https://play.google.com/store/apps/details?id=com.langit7.jjf"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;

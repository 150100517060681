import React from "react";
import "./Indieapps.css";
import {DataIndieApps} from "./DataIndieApps";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/zoom";
import { Zoom, Navigation, Pagination, Autoplay } from "swiper";

const Indieapps = () => {
  return (
    <section className="about section" id="about">
      <h2 className="section__title">Indie Story</h2>
      <div className="center-content">
        <span className="section__subtitle1">
        Now I'm trying to building some indie apps. If you interested about my indie apps, you can check it out below.
        </span>
        <span className="section__subtitle1">
        So far now i have 1 indie app that i have published on Google Play Store & coming soon to Apple App Store, ExpireMate.
        </span>
      </div>
      
      <Swiper
        className="testimonial__container"
        spaceBetween={24}
        effect={"fade"}
        fadeEffect={{
          crossFade: true,
        }}
        zoom={true}
        autoplay={{
          delay: 4000,
        }}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          576: {
            slidesPerView: 1,
            spaceBetween: 48,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 48,
          },
        }}
        modules={[Zoom, Navigation, Pagination, Autoplay]}
      >
        {DataIndieApps.map(({ id, image, url }) => {
          return (
            <SwiperSlide className="testimonial__card" key={id}>
              <a href={url} target="_blank">
              <div className="swiper-zoom-container">
                <img src={image} alt="client" />
              </div>
              </a>
            </SwiperSlide>
          );
        })}
      </Swiper>
      
    </section>
  );
};

export default Indieapps;

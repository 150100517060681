import "./App.css";
import Header from "./components/header/Header";
import Home from "./components/home/Home";
import About from "./components/about/About";
import Skills from "./components/skills/Skills";
import Services from "./components/services/Services";
import Qualification from "./components/qualification/Qualification";
import Testimonials from "./components/testimonials/Testimonials";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import ScrollUp from "./components/scrollup/ScrollUp";
import Portfolio from "./components/projects/Portfolio";
import Carousell from "./components/carousell/Carousel";
import Indieapps from "./components/indieapps/Indieapps";


const App = () => {
  return (
    <>
      <Header />
      <main className="main">
        <Home />
        <About />
        <Indieapps/>
        <Skills />
        <Services />
        <Qualification />
        {/* <Portfolio /> */}
        <Testimonials />
        {/* <Carousell /> */}

        <Contact />
      </main>
      <Footer />
      <ScrollUp />
    </>
  );
};

export default App;
